<template>
  <div>
    <b-container>
      <b-row class="d-flex align-items-center curve slider-gradient">
        <b-col cols="12" md="6" class="">
          <span>با مایندولوژی</span>
          <h2 class="my-4 text-primary font-weight-bold">
            انلاین با متخصص روانشناس صحبت کن
          </h2>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
          <p>
            مایندولوژی پلتفرم جامع روانشناسی با کمک تکنولوژی به شما کمک میکنه تا
            تجربه درمان سریعتر و هدفمند تری با روانشناس خودتون داشته باشید
          </p>
          <!-- <form @submit.stop.prevent>
            <div
              class="row no-gutters align-items-center border rounded-pill py-1 px-1"
            >
              <div class="col-auto">
                <b-icon icon="search" color="gray" class="ms-5"></b-icon>
              </div>
              <div class="col mx-2">
                <input
                  class="form-control border-0 bg-transparent"
                  type="search"
                  placeholder="دنبال چه چیزی میگردید؟"
                />
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-secondary text-white rounded-pill py-4 px-6"
                  type="submit"
                >
                  جستجو
                </button>
              </div>
            </div>
          </form> -->
        </b-col>

        <b-col cols="12" md="6" class="text-center">
          <!-- <img
            src="@/assets/images/main-slider/1.jpg"
            alt="slider"
            class="my-1 stroke"
          /> -->
          <b-carousel
            id="carousel-home"
            style="
              text-shadow: 0px 0px 2px #000;
              border-radius: 40px;
              overflow: hidden;
            "
            class="w-50 mx-auto shadow"
            :interval="3000"
          >
            <b-carousel-slide
              :img-src="require('@/assets/images/main-slider/1.jpg')"
            ></b-carousel-slide>
            <b-carousel-slide
              :img-src="require('@/assets/images/main-slider/2.jpg')"
            ></b-carousel-slide>
            <b-carousel-slide
              :img-src="require('@/assets/images/main-slider/3.jpg')"
            ></b-carousel-slide>
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <!-- last doctors -->
      <!-- 
      <b-row cols="12">
        <v-sheet class="mx-auto" elevation="0" max-width="100%">
          <v-slide-group  class="pa-4" show-arrows center-active mobile-break-point="1000">     

            <v-slide-item
              v-for="(item, index) in LastSignUpDoctor.data"
              :key="index"
            >
              <v-card
                class="ma-4 overflow-hidden rounded-xl p-2"
                max-height="400"
                width="220"
              >
                <v-img height="220" width="220" :src="item.pic"> </v-img>
                <span class="h6 d-block">
                  {{ item.firstname + " " + item.lastname }}
                </span>
                <span
                  style="font-size: 12px"
                  class="font-weight-bold"
                >
                {{item.bio}}
                </span>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </b-row> -->
      <v-container class="mt-2">
        <span class="f18 font-weight-bold primary--text">آخرین متخصصین</span>
        <v-row no-gutters class="mt-2">
          <v-col
            v-for="(item, index) in LastSignUpDoctor"
            :key="index"
            cols="6"
            md="3"
            xs="6"
          >
            <router-link
              :href="item.username"
              :to="item.username"
              class="text-decoration-none"
            >
              <v-card
                elevation="1"
                class="
                  mx-auto
                  pa-4
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  rounded-lg
                "
                outlined
                tile
                height="235"
                width="85%"
              >
                <v-avatar color="primary" size="105">
                  <v-img
                    :src="
                      item.pic != null && item.pic != ''
                        ? item.pic
                        : require('@/assets/images/profile.jpg')
                    "
                  >
                  </v-img>
                </v-avatar>
                <span class="f16 mt-3 font-weight-bold text-primary text-center">{{
                  item.firstname + " " + item.lastname
                }}</span>

                <v-tooltip bottom max-width="200" content-class="text-center">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="mt-2 font-weight-light text-truncate f14"
                      style="max-width: 80%;">
                      {{ item.bio }}
                    </span>
                    </template> 
                    <span>{{ item.bio }}</span>
                </v-tooltip>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-container>

      <!-- ---------------------- -->
      <b-row class="d-flex align-items-center mt-2">
        <b-col cols="12" md="6">
          <div
            class="
              wh-40
              shadow
              rounded-lg
              my-2
              text-center
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <img src="@/assets/images/question.png" alt="question" width="12" />
          </div>
          <h2 class="my-4 text-primary font-weight-bold">
            چگونه می توانم از خدمات <br />
            مایندولوژی استفاده کنم؟
          </h2>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
          <p>
            استفاده از خدمات مایندولوژی شامل سوال و جواب از متخصص روان شناس، تست
            های شخصیتی با تحلیل اختصاصی و ... است که کاملا رایگان می باشد. برای شروع، وارد وب اپلیکشن مایندولوژی شوید .
          </p>
          <!-- <span class="text-secondary">همین الان شروع کن</span
          ><img src="@/assets/images/play.png" alt="play" class="ms-2" /> -->
        </b-col>

        <b-col cols="12" md="6">
          <img
            src="@/assets/images/slider2.png"
            alt="slider2"
            class="my-4 w-100"
          />
        </b-col>
      </b-row>
    </b-container>

    <div class="bg-blue-light mt-2 d-none">
      <b-container>
        <b-row class="d-flex align-items-center">
          <b-col cols="12" md="12">
            <h2 class="my-4 text-primary font-weight-bold">
              مایندولوژی چه خدماتی <br />
              را به من ارائه می دهد؟
            </h2>
            <img src="@/assets/images/divider.png" alt="divider" class="mt-4" />
          </b-col>
          <b-col cols="12" md="6">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1 border-0">
                <b-card-header
                  header-tag="header"
                  class="p-1 border-0"
                  role="tab"
                >
                  <b-button
                    block
                    v-b-toggle.accordion-1
                    variant="white"
                    class="border-0"
                    >خدمات پلتفرم مایندولوژی برای مراجعین</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <ul class="px-4 faq">
                        <li>ارائه سوال و جواب از متخصصین</li>
                        <li>
                          ارائه تست های شخصیتی به همراه تحلیل های تخصصی و علمی
                          کاملا رایگان
                        </li>
                        <li>دسترسی به مجله ی سلامت</li>
                        <li>دسترسی پادکست های تخصصی</li>
                      </ul>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1 border-0">
                <b-card-header
                  header-tag="header"
                  class="p-1 border-0"
                  role="tab"
                >
                  <b-button
                    block
                    v-b-toggle.accordion-2
                    variant="white"
                    class="border-0"
                    >خدمات رایگان</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <ul class="px-4 faq">
                        <li>ارائه سوال و جواب از متخصصین</li>
                        <li>
                          ارائه تست های شخصیتی به همراه تحلیل های تخصصی و علمی
                          کاملا رایگان
                        </li>
                        <li>دسترسی به مجله ی سلامت</li>
                        <li>دسترسی پادکست های تخصصی</li>
                      </ul>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1 border-0">
                <b-card-header
                  header-tag="header"
                  class="p-1 border-0"
                  role="tab"
                >
                  <b-button
                    block
                    v-b-toggle.accordion-3
                    variant="white"
                    class="border-0"
                    >خدمات پلتفرم برای مشاورین</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <ul class="px-4 faq">
                        <li>ارائه سوال و جواب از متخصصین</li>
                        <li>
                          ارائه تست های شخصیتی به همراه تحلیل های تخصصی و علمی
                          کاملا رایگان
                        </li>
                        <li>دسترسی به مجله ی سلامت</li>
                        <li>دسترسی پادکست های تخصصی</li>
                      </ul>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1 border-0">
                <b-card-header
                  header-tag="header"
                  class="p-1 border-0"
                  role="tab"
                >
                  <b-button
                    block
                    v-b-toggle.accordion-4
                    variant="white"
                    class="border-0"
                    >موارد رمز شده</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-4"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <ul class="px-4 faq">
                        <li>ارائه سوال و جواب از متخصصین</li>
                        <li>
                          ارائه تست های شخصیتی به همراه تحلیل های تخصصی و علمی
                          کاملا رایگان
                        </li>
                        <li>دسترسی به مجله ی سلامت</li>
                        <li>دسترسی پادکست های تخصصی</li>
                      </ul>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <img
              src="@/assets/images/slider3.png"
              alt="slider3"
              class="my-4 w-100"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container fluid>
      <b-row class="d-flex align-items-center">
        <b-col cols="12" md="12" class="text-center">
          <h2 class="my-4 text-center text-primary font-weight-bold">
            با انجام تست های شخصیتی <br />
            سلامت ذهن خود را بسنجید
          </h2>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
          <p class="w-50 mx-auto">
            مجموع تست هاي روان شناسي به منظور شناخت بيشتر ويژگي هاي شخصيتي
            ،اخلاقي و آگاهي نسبت به نقاط قوت و ضعفتان براي خود شناسي بيشتر است.
            آشكار شدن احساسات و هيجانات دروني به ايجاد روابط موثر بين فردي و
            انتخاب درست موقعيت هاي مناسب شغلي ،ازدواج و راه هاي رسيدن به موفقيت
            در زندگي فردي شما کمک میکند.
          </p>
        </b-col>
        <b-col cols="12" md="12" class="text-center">
          <carousel
            id="carousel-exams"
            class="ltr"
            :autoplay="true"
            :nav="true"
            :items="4"
            :loop="true"
            :center="true"
            :dots="false"
            :responsive="{
              0: { items: 1 },
              540: { items: 2 },
              720: { items: 3 },
              960: { items: 4 },
            }"
            :navText="nav_buttons"
          >
            <div
              class="item position-relative mb-4"
              v-for="item in tests"
              :key="item.id"
              v-on:click="Hartman(item)"
              role="button"
            >
              <div
                class="card p-4 m-2 text-center curve shadow-sm"
                style="border-color: #e8e8e8"
              >
                <img
                  :src="getImage(item.icon)"
                  class="w-auto mx-auto py-2"
                  height="100"
                />
                <div class="lead bold mt-3 position-relative w-auto">
                  <span class="f14 font-weight-bold text-center">{{ item.title }}</span>
                  <img
                    v-if="item.type == 'free'"
                    src="@/assets/images/free.png"
                    alt="divider"
                    class="w-auto position-absolute"
                    style="top: -1.1em; left: 0.5em"
                  />
                  <img
                    v-else-if="item.type == 'soon'"
                    src="@/assets/images/soon.png"
                    alt="divider"
                    class="w-auto position-absolute"
                    style="top: -1.1em; left: 0.5em"
                  />
                </div>
                <p class="f12 text-justify rtl">
                  {{ item.desc }}
                </p>
              </div>
              <img
                v-if="item.type == 'free'"
                src="@/assets/images/play.png"
                alt="play"
                class="w-auto position-absolute"
                style="bottom: -1em; left: 2.5em"
              />
              <img
                v-else-if="item.type == 'soon'"
                src="@/assets/images/play.png"
                alt="play"
                class="w-auto position-absolute"
                style="
                  bottom: -1em;
                  left: 2.5em;
                  filter: grayscale(100%) sepia(0.9);
                "
              />
            </div>
          </carousel>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="!isMobile()">
      <b-row
        class="d-flex align-items-center position-relative section-category"
      >
        <b-col cols="12" md="5">
          <h2
            class="position-absolute text-primary font-weight-bold"
            style="top: -2em; right: 1em"
          >
            چه دسته بندی هایی در <br />
            مایندولوژی وجود داره؟
          </h2>
          <img
            src="@/assets/images/cat-icons.png"
            alt="play"
            class="position-absolute centered-axis-y right-2"
            width="15%"
          />

          <p
            class="position-absolute text-white text-small"
            style="top: 14rem; right: 5em"
          >
            برای استفاده از امکانات کامل و مشاهده زیر مجموعه دسته بندی ها
            <br />
            نرم افزار مایندولوژی را نصب و از خدمات آن استفاده کنید
          </p>
        </b-col>
        <b-col cols="12" md="6" class="text-center">
          <carousel
            id="carousel-categories"
            :autoplay="true"
            :nav="true"
            :items="3"
            :loop="true"
            :center="true"
            :dots="false"
            :responsive="{
              0: { items: 1 },
              540: { items: 2 },
              720: { items: 2 },
              960: { items: 3 },
            }"
            :navText="nav_buttons_category"
            class="ltr"
          >
            <div class="item" v-for="item in categories" :key="item.id">
              <img :src="getImage(item.pic)" class="px-3" />
            </div>
          </carousel>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="isMobile()">
      <b-row class="d-flex align-items-center section-category-mobile ms-0">
        <b-col cols="12">
          <h2 class="text-primary font-weight-bold text-center">
            چه دسته بندی هایی در <br />
            مایندولوژی وجود داره؟
          </h2>
        </b-col>
        <b-col cols="3">
          <img src="@/assets/images/cat-icons.png" alt="play" />
        </b-col>
        <b-col cols="9" class="text-center">
          <carousel
            id="carousel-categories"
            :autoplay="true"
            :nav="true"
            :items="3"
            :loop="true"
            :center="true"
            :dots="false"
            :responsive="{
              0: { items: 1 },
              600: { items: 1 },
              1000: { items: 3 },
            }"
            :navText="nav_buttons_category_mobile"
            class="ltr"
          >
            <div class="item" v-for="item in categories" :key="item.id">
              <img :src="getImage(item.pic)" class="px-3" />
            </div>
          </carousel>
        </b-col>
        <b-col cols="12">
          <p class="text-white text-center text-small">
            برای استفاده از امکانات کامل و مشاهده زیر مجموعه دسته بندی ها نرم
            افزار مایندولوژی را نصب و از خدمات آن استفاده کنید
          </p>
        </b-col>

        <b-col cols="12">
          <v-spacer class="my-4"></v-spacer>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="isLogin">
      <b-row>
        <b-col cols="12" md="12" class="text-center mt-4">
          <h2 class="my-4 text-center text-primary font-weight-bold">
            با صدا و تصور با شما <br />
            صحبت می کنیم
          </h2>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
          <h6 class="text-secondary my-0 text-center">
            آخرین پادکست ها و ویدئو های منتشر شده در اپلیکشن
          </h6>
        </b-col>
        <b-col cols="12" md="6">
          <b-card class="py-0 my-0 curve">
            <b-card-header
              class="d-flex align-items-center bg-white border-0 py-0 my-0"
            >
              <img
                src="@/assets/images/microphone.png"
                alt="mic"
                height="50"
                width="auto"
              />
              <h6 class="text-secondary my-0 ms-2">آخرین پادکست ها</h6>
              <b-link class="small text-black-50 ms-auto d-none">همه</b-link>
            </b-card-header>
            <b-card-body>
              <div
                class="content-items"
                v-for="podcast in podcasts"
                :key="podcast.id"
              >
                <b-media vertical-align="center">
                  <template #aside>
                    <v-img
                      :aspect-ratio="4/3"
                      :src="podcast.picture"
                      :lazy-src="require('@/assets/images/placeholder.png')"
                      width="80"
                      alt="placeholder"
                      class="me-3 rounded"
                    >
                    </v-img>
                  </template>
                  <h6 class="mt-0 mb-1 font-weight-bold text-medium">
                    {{ podcast.title }}
                  </h6>
                  <p class="mb-0 text-muted font-weight-normal text-small">
                    {{ podcast.publisherName }}
                  </p>
                  <span class="small text-black-50 font-weight-light text-small"
                    ><b-icon icon="clock" class="me-2"></b-icon
                    >{{
                      podcast.createDate | moment("jYYYY/jMM/jDD HH:mm")
                    }}</span
                  >
                </b-media>
                <hr />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" md="6">
          <b-card class="py-0 my-0 curve">
            <b-card-header
              class="d-flex align-items-center bg-white border-0 py-0 my-0"
            >
              <img
                src="@/assets/images/video-camera.png"
                alt="video"
                height="50"
                width="auto"
              />
              <h6 class="text-secondary my-0 ms-2">آخرین ویدئو ها</h6>
              <b-link class="small text-black-50 ms-auto d-none">همه</b-link>
            </b-card-header>
            <b-card-body>
              <div
                class="content-items"
                v-for="video in videos"
                :key="video.id"
              >
                <b-media vertical-align="center">
                  <template #aside>
                    <v-img
                      :aspect-ratio="4/3"
                      :src="video.picture"
                      :lazy-src="require('@/assets/images/placeholder.png')"
                      width="80"
                      alt="placeholder"
                      class="me-3 rounded"
                    >
                    </v-img>
                  </template>
                  <h6 class="mt-0 mb-1 font-weight-bold text-medium">
                    {{ video.title }}
                  </h6>
                  <p class="mb-0 text-muted font-weight-normal text-small">
                    {{ video.publisherName }}
                  </p>
                  <span class="small text-black-50 font-weight-light text-small"
                    ><b-icon icon="clock" class="me-2"></b-icon
                    >{{
                      video.createDate | moment("jYYYY/jMM/jDD HH:mm")
                    }}</span
                  >
                </b-media>
                <hr />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="isLogin">
      <b-row class="d-flex align-items-center">
        <b-col cols="12" md="12" class="text-center">
          <h2 class="my-4 text-center text-primary font-weight-bold">
            مجله مایندولوژی <br />
            به شما آگاهی میده
          </h2>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
          <h6 class="text-secondary my-0 text-center">
            آخرین مطالب منتشر شده در اپلیکشن
          </h6>
          <br />
          <b-button
            class="mx-auto btn btn-seconadry text-white mt-4 rounded-lg d-none"
            >مطالعه بیشتر</b-button
          >
        </b-col>
        <b-col cols="12" md="12" class="text-center">
          <b-row>
            <b-col
              cols="12"
              md="3"
              v-for="blog in blogs"
              :key="blog.id"
              class="category-item"
            >
              <v-img
                :src="blog.picture"
                :lazy-src="require('@/assets/images/placeholder.png')"
                alt="placeholder"
                class="curve w-100 shadow"
                :aspect-ratio="4/3"
              >
              </v-img>

              <b-card class="curve mt-2 shadow">
                <v-chip-group column>
                  <v-chip v-for="category in blog.groups" :key="category.id">
                    {{ category.value }}
                  </v-chip>
                </v-chip-group>

                <h6 class="my-2 font-weight-bold text-medium">
                  {{ blog.title }}
                </h6>
                <p class="my-2 font-weight-normal text-small max-3-line">
                  {{ blog.desc }}
                </p>

                <hr />

                <div class="d-flex align-items-center">
                  <v-avatar size="40">
                    <v-img
                      :src="blog.publisherPic"
                      :lazy-src="require('@/assets/images/profile.jpg')"
                      alt="author"
                      class="rounded"
                    >
                    </v-img>
                  </v-avatar>
                  <span
                    v-if="blog.publisherName != null"
                    class="ms-2 text-small"
                    >{{ blog.publisherName }}</span
                  >
                  <span v-else class="ms-2 text-small">مایندولوژی</span>
                  <div class="ms-auto">
                    <span class="text-muted d-flex align-items-center"
                      ><span class="text-small">{{ blog.viewCount }}</span
                      ><b-icon class="ms-1" icon="eye-fill"></b-icon
                    ></span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import apiService from "@/http/ApiServiceWebsite.js";

export default {
  data() {
    return {
      isLogin : false,

      LastSignUpDoctor: [],
      nav_buttons: [
        '<div class="nav-btn prev-slide"></div>',
        '<div class="nav-btn next-slide"></div>',
      ],
      nav_buttons_category: [
        '<div class="nav-btn-cat prev-slide-cat"></div>',
        '<div class="nav-btn-cat next-slide-cat"></div>',
      ],
      nav_buttons_category_mobile: [
        '<div class="nav-btn-cat-mobile prev-slide-cat-mobile"></div>',
        '<div class="nav-btn-cat-mobile next-slide-cat-mobile"></div>',
      ],

      blogs: [],
      podcasts: [],
      categories: [
        {
          id: 0,
          pic: "cat-1",
        },
        {
          id: 1,
          pic: "cat-2",
        },
        {
          id: 2,
          pic: "cat-3",
        },
      ],

      tests: [
        {
          id: 4,
          title: "تست هارتمن",
          desc: "تست شخصيت هارتمن يكي از انواع تست هاي شخصيتي ست.در اين تست دكتر تيلور هارتمن از يك نظام رنگي استفاده كرده تا رمز شخصيتي شما را مشخص كند.از ميان چهار رنگ قرمز ،آبي ،سفيد و زرد يكي رمز شخصيتي شماست.هر رنگ مجموعه اي از صفات ،توانمندي ها و محدوديت هاست",
          type: "free",
          icon: "car-5",
        },
        {
          id: 0,
          title: "تست سبک انگیزشی غالب",
          desc: "در اين تست، مك كلند بر اساس سبك انگيزشي كاركنان به رهبران اين امكان را مي دهد مطمئن شوند كه همه كاركنان در كاري كه انجام مي دهند فعال،باانگيزه و راضي هستند.",
          type: "soon",
          icon: "car-1",
        },
        {
          id: 1,
          title: "تست هوش هیجانی بارآن",
          desc: "اين تست به شما كمك ميكند با شناخت صحيح هيجانات و احساسات با ديگران ارتباط موثر تري داشته باشيد و بتوانيد هجانات خود را به درستي مدريت كنيد.",
          type: "soon",
          icon: "car-2",
        },
        {
          id: 2,
          title: "تست هوش ریون",
          desc: "اين تست توسط ريون به منظور اندازه گيري بهره هوشي شما ساخته شده است و معمولا براي مدارس و آزمون هاي استخدام استفاده مي شود.",
          type: "soon",
          icon: "car-3",
        },
        {
          id: 3,
          title: "تست توانایی های شناختی",
          desc: "اين تست ابزاري براي سنجش استعداد هست و توانايي هاي شناختي افراد مثل حل مسئله ،برنامه ريزي،توجه و… را مي سنجد.",
          type: "soon",
          icon: "car-4",
        },
      ],
    };
  },
  methods: {
    getImage(src) {
      return require(`@/assets/images/${src}.png`);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getPosts() {
      apiServiceApp.getPostspublishPaging(0, 4).then((response) => {
        this.blogs = response.data.data;
      });
    },
    getPodcasts() {
      apiServiceApp.getPodcastspublishPaging(0, 4).then((response) => {
        this.podcasts = response.data.data;
      });
    },
    getVideos() {
      apiServiceApp.getVideospublishPaging(0, 4).then((response) => {
        this.videos = response.data.data;
      });
    },
    lastDoctros() {
      apiService.latestDoctors().then((response) => {
        this.LastSignUpDoctor = response.data.slice(0, 4);
      });
    },
  },
  mounted() {
    this.lastDoctros(); 
    this.getPosts();
    this.getPodcasts();
    this.getVideos();

    this.isLogin = this.$store.getters.isLoggedIn;

    if (localStorage.getItem("refresh")) {
      this.$router.go();
      localStorage.removeItem("refresh");
    }
  },
};
</script>
<style scoped>
.hover:hover {
  opacity: 0.9;
}
</style>
